import React from "react";

import { BACKEND_BASE_URL } from "../../constant/constants";

import Layout from "../../components/layout";
import Resources from "../../container/resources";

const ResourcesPage = arg => {
  return (
    <Layout location={arg.location}>
      <Resources data={arg.serverData.resource_info} year={arg.year} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const resource_info_res = await fetch(
      BACKEND_BASE_URL + "resources?year=" + context.params.year
    );

    if (!resource_info_res.ok) {
      throw new Error(`Response failed`);
    }

    return {
      props: {
        resource_info: await resource_info_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default ResourcesPage;
