import React, { useMemo } from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import { Resource } from "../components/resource";
import { Dropdown } from "../components";
import { isNotEmpty } from "../utils/utils";

const Resources = props => {
  const { data, year } = props;
  const { yearData } = useSelector(state => state.resourcesReducer);

  const years = useMemo(() => {
    if (isNotEmpty(yearData)) {
      return yearData.map((item, index) => {
        return {
          id: index,
          name: item,
          slug: item,
        };
      });
    }
    return [];
  }, [yearData]);

  const onChange = e => {
    navigate("/resources/" + e.target.value);
  };

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Dropdown
              data={years}
              selected={year}
              onChange={onChange}
              sort={false}
            />
            <Resource data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
