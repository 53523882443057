import React from "react";

import { getExtensionFromPath } from "../utils/utils";

const Resource = props => {
  const { data: resourceData } = props;

  return (
    <div className="resource">
      <div className="row">
        {resourceData?.data?.map(item => {
          return (
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3"
              key={item?.uuid}
            >
              <div className="resource__card">
                <img src={item?.image_url} alt={item?.title} />
                <h6>
                  {item?.title} - {item?.year}
                </h6>
                <p> {item?.description}</p>
                <a href={item?.asset_url} target="_blank" rel="noreferrer">
                  View
                </a>
                <div className="resource__card-watermark">
                  {getExtensionFromPath(item?.asset_url).toUpperCase()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Resource };
